<template>
  <div>
    <h3 v-if="title"><span class="span-icon"></span>{{ title }}</h3>
    <cur-list ref="curList" :filters="filters"></cur-list>
  </div>
</template>

<script>
import CurList from "components/course/CurList"
  export default {
    data() {
      return {
        filters: {},
        title: '',
      }
    },
    components: {
      CurList
    },
    created() {
      this.init()
    },
    methods: {
      init() {
        this.filters = {}
        let keys = Object.keys(this.$route.query)
        //标签页title
        if(this.$route.query.price === 'free') {
          document.title = '免费课程-未来网络学堂';
          this.title = '免费课程'
        }
        else if(this.$route.query.sort === 'new') {
          document.title = '最新课程-未来网络学堂';
          this.title = '最新课程'
        }
        if(keys.indexOf('price') >= 0){
          this.filters['final_price'] = 0
        }
        else if(keys.indexOf('sort') >= 0){
          this.filters['final_price'] = '>0'
          this.filters['sort_key'] = 'publish_at'
        }
        else if(keys.indexOf('query') >= 0){
          this.filters['query'] = this.$route.query.query
          document.title = '课程搜索-未来网络学堂'
          if(this.filters['query'] === '') {
            // this.title = this.originTitle
            document.title = '全部课程-未来网络学堂'
            this.title = '全部课程'
          } else {
            this.title = '搜索：' + this.filters['query']
          }
        }

        //reload 7moor js
        const s = document.createElement('script');
        s.type = 'text/JavaScript';
        s.src = 'https://webchat.7moor.com/javascripts/7moorInit.js?accessId=a4c3d330-5dcc-11ea-b7ff-07dd5ece4b95&autoShow=true&language=ZHCN';
        document.body.appendChild(s);

      }
    },
    watch: {
      '$route' (to, from) {
        if(to.name === 'CourseSearch'){
          this.init()
          // this.$refs.curList.updateFilters(this.filters)
          // this.$refs.curList.getCurList()
          // this.$refs.curList.getCateList()
        }
      }
    }
  }
</script>

<style lang="scss">
h3 {
  width: 100%;
  max-width: 1200px;
  margin: 0 auto;
  margin-bottom: 20px;
  font-size: 18px;
}

.span-icon {
  position: relative;
  top: 2px;
  margin-right: 7px;
  width: 4px;
  height: 18px;
  background: #009DD9;
  display: inline-block;
}
</style>
